import { Fragment, Suspense, useRef } from "react";
import { useTranslation } from "react-i18next";
import { omit } from "lodash";
import { Controller } from "react-hook-form";
import { Box, Stack, Typography, CircularProgress } from "@mui/material";
import * as schemas from "./schemas";
import * as controls from "./controls";

export interface Indicator {
    name: string;
    parameters: schemas.ParametersType;
}

export interface ParametersProps {
    /**
     * The index of the indicator in the list of selected indicators
     * Refers to `indicators[indicatorIndex]`
     */
    indexOfIndicator: number;
    parameters: schemas.ParametersType;
    control: any;
    getValues: any;
}

export function Parameters({
    parameters,
    indexOfIndicator,
    control,
    getValues,
}: ParametersProps) {
    const { t } = useTranslation();

    const possibleKeysOfParameterValues = useRef<string[]>([]);

    const addPossibleKeyOfParameterValuesUnique = (...keys: string[]) => {
        keys.forEach((key) => {
            if (!possibleKeysOfParameterValues.current.includes(key)) {
                possibleKeysOfParameterValues.current.push(key);
            }
        });
    };

    const parametersParsed = schemas.parametersSchema.safeParse(parameters),
        actualParameters = parametersParsed.data;

    if (!parametersParsed.success) {
        console.error(
            `Invalid parameters: ${JSON.stringify(parametersParsed.error)}`
        );
    }

    if (actualParameters === undefined) {
        return <CircularProgress />;
    }

    return (
        <Box display="flex" flexDirection="column" gap={1}>
            {actualParameters
                .map((parameter, indexOfParameter) => {
                    const keyPrefix = `indicators[${indexOfIndicator}].parameters[${indexOfParameter}]`;

                    switch (parameter.type) {
                        case schemas.ParameterTypeEnum.REFERENCE_DATE:
                            addPossibleKeyOfParameterValuesUnique(
                                "possibleValues"
                            );
                            return {
                                parameter,
                                title: t("parameters.reference-date"),
                                components: (
                                    <Controller
                                        control={control}
                                        name={`${keyPrefix}.possibleValues`}
                                        render={({ field }) => (
                                            <controls.DateControl
                                                {...omit(field, ["ref"])}
                                                multiple
                                            />
                                        )}
                                    />
                                ),
                            };

                        case schemas.ParameterTypeEnum.QUARTER_AND_YEAR:
                            addPossibleKeyOfParameterValuesUnique(
                                "quarters",
                                "years"
                            );
                            return {
                                parameter,
                                title: t("parameters.quarter-and-year"),
                                components: (
                                    <MultiControl key={indexOfParameter}>
                                        <Controller
                                            control={control}
                                            name={`${keyPrefix}.quarters`}
                                            render={({ field }) => (
                                                <controls.QuarterControl
                                                    {...omit(field, ["ref"])}
                                                    options={
                                                        parameter.possibleQuarters ??
                                                        []
                                                    }
                                                />
                                            )}
                                        />
                                        <Controller
                                            control={control}
                                            name={`${keyPrefix}.years`}
                                            render={({ field }) => (
                                                <controls.YearControl
                                                    {...omit(field, ["ref"])}
                                                    maxInclusive={
                                                        parameter.maxYearValue
                                                    }
                                                    minInclusive={
                                                        parameter.minYearValue
                                                    }
                                                />
                                            )}
                                        />
                                    </MultiControl>
                                ),
                            };

                        case schemas.ParameterTypeEnum.DATE_RANGE:
                            // eslint-disable-next-line no-case-declarations -- This is a valid case
                            const [{ startDate, endDate } = {}] =
                                getValues([
                                    `indicators.${indexOfIndicator}.parameters.${indexOfParameter}`,
                                ]) ?? [];

                            // eslint-disable-next-line no-case-declarations -- This is a valid case
                            const actualMinEndDate = startDate?.at(0)
                                ? new Date(startDate?.at(0))
                                : parameter.endMinDate;

                            // eslint-disable-next-line no-case-declarations -- This is a valid case
                            const actualMaxStartDate = endDate?.at(0)
                                ? new Date(endDate?.at(0))
                                : parameter.startMaxDate;

                            addPossibleKeyOfParameterValuesUnique(
                                "startDate",
                                "endDate"
                            );
                            return {
                                parameter,
                                title: t("parameters.date-range"),
                                components: (
                                    <MultiControl>
                                        <Controller
                                            control={control}
                                            name={`${keyPrefix}.startDate`}
                                            render={({ field }) => (
                                                <controls.DateControl
                                                    {...omit(field, ["ref"])}
                                                    multiple={false}
                                                    showDatePanel={false}
                                                    label={t(
                                                        "parameters.start-date"
                                                    )}
                                                    minDate={
                                                        parameter.startMinDate
                                                    }
                                                    maxDate={actualMaxStartDate}
                                                />
                                            )}
                                        />
                                        <Controller
                                            control={control}
                                            name={`${keyPrefix}.endDate`}
                                            render={({ field }) => (
                                                <controls.DateControl
                                                    {...omit(field, ["ref"])}
                                                    multiple={false}
                                                    showDatePanel={false}
                                                    label={t(
                                                        "parameters.end-date"
                                                    )}
                                                    minDate={actualMinEndDate}
                                                    maxDate={
                                                        parameter.endMaxDate
                                                    }
                                                />
                                            )}
                                        />
                                    </MultiControl>
                                ),
                            };

                        default:
                            throw new Error(
                                `Unknown parameter type: ${(parameter as any)?.type}`
                            );
                    }
                })
                .map(({ title, components }, index) => {
                    return (
                        <Fragment key={index}>
                            <Suspense fallback={<CircularProgress />}>
                                <Typography variant="overline">
                                    {title}
                                </Typography>
                                {components}
                            </Suspense>
                        </Fragment>
                    );
                })}
        </Box>
    );
}

function MultiControl({ children }: { children: React.ReactNode }) {
    return (
        <Box sx={{ containerType: "inline-size" }}>
            <Stack
                gap={2}
                sx={{
                    ["@container (min-width: 320px)"]: {
                        flexDirection: "row",
                    },
                }}
            >
                {children}
            </Stack>
        </Box>
    );
}

export default Parameters;
